<template>
  <button class="tag" @click="$emit('click')" :disabled="disabled">
    <span class="tag__text">{{ value }}</span>
    <span class="tag__close" v-if="close" @click="!disabled ? $emit('deleteTag') : false">
      <IconClose width="6" height="6" viewBox="0 0 13 13"></IconClose>
    </span>
  </button>
</template>
<script>
import IconClose from "@/components/icons/IconClose.vue";
export default {
  props: {
    value: [String, Number],
    close: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  components: { IconClose },
};
</script>
<style lang="scss" scoped>
.tag {
  height: 30px;
  //min-width: 6.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: $blueLight;
  margin: 0 5px 12px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &[disabled] {
    opacity: 0.7;
    cursor: default;
  }
  &__text {
    @include type();
    color: $blue;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 4px;
    border: 1px solid $blue;
    margin-left: 5px;
    svg {
      width: auto;
      height: auto;
      &::v-deep g {
        fill: $blueDark;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  &--selected {
    background-color: rgba($color: $black, $alpha: 0.5);
    .tag__text {
      color: $white;
    }
    .tag__close {
      border-color: $white;
      svg::v-deep g {
        fill: $white;
      }
    }
  }
  &--capitalize {
    .tag__text {
      text-transform: capitalize;
    }
  }
  &--xs {
    .tag__text {
      font-size: 14px;
    }
  }
  &--text {
    cursor: default;
  }
  &--public {
    @include publictextNeue($fw: 500);
    height: 4.8rem;
    //background: $lightBlack;
    background: transparent;
    border: 0.4px solid #a8a8a8;
    border-radius: 20px;
    padding: 0 20px;
    transition: all 0.3s ease;
    margin: 5px 0 5px 10px;
    .tag__text {
      font-family: $fontFrutiger;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: $white;
      text-transform: uppercase;
    }
    &.tag--selected {
      background-color: $white;
      .tag__text {
        color: $lightBlack;
      }
    }
    &-outline {
      margin: 0;
      flex-direction: row-reverse;
      gap: 4px;
      height: auto;
      padding: 7px 12px 7px 8px;
      .tag {
        &__text {
          text-transform: initial;
        }
        &__close {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          border: none;
          margin: 0;
          svg {
            width: 10px;
            height: 10px;
            &::v-deep g {
              fill: $white;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
  &--periods {
    .tag__text {
      text-transform: none;
    }
  }
  &--default {
    margin: 0;
    height: 40px;
    padding: 12px 10px;
    .tag__text {
      font-weight: 400;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 35px;
      .tag__text {
        font-size: 13px;
      }
    }
  }
}
</style>

