<template>
  <div
    class="additional-tags"
    @mouseenter="mouseenter"
    @click="!visible ? setRect() : false"
    @mouseleave="mouseleave"
  >
    <IconButton
      v-if="isFilter"
      class="button--full-width button--public button--public-right"
      @click="selected = true"
      text="Filter"
    >
      <template #right><IconFilter></IconFilter></template>
    </IconButton>
    <span
      v-else
      class="additional-tags__count"
      :class="{ 'additional-tags__count--selected': selected }"
      @click="selected = true"
      tabindex="0"
    >
      +{{ items.length }}
    </span>

    <div
      ref="additionalPopUp"
      class="additional-tags__body"
      :class="{ 'additional-tags__body--visible': visible }"
      @mousedown="
        isDown = true;
        mousedown($event);
      "
      @mouseleave="mouseleave"
      @mouseup="isDown = false"
      @mousemove="mousemove($event)"
    >
      <div class="additional-tags__container" v-if="isPublic">
        <SubscriptionInput
          v-if="isSearch"
          class="additional-tags__search form-control--additional"
          placeholder="Search"
          :icon="true"
          :value="search"
          :isEventInput="true"
          @input="inputSearch"
        >
          <template #icon>
            <IconClose
              class="additional-tags__search-clear"
              color="#fff"
              @click.native="inputSearch('')"
            ></IconClose>
          </template>
        </SubscriptionInput>
        <Tag
          class="tag--public"
          :class="{ 'tag--selected': tag.selected }"
          v-for="(tag, index) in searchItems"
          :key="`tag-${index}`"
          :value="tag.title"
          @click="$emit('changeFilterValue', tag)"
        />
      </div>
      <Tag
        v-else
        class="additional-tags__item"
        v-for="(tag, index) in items"
        :key="`tag-${index}`"
        :value="tag.title"
      ></Tag>
    </div>
  </div>
</template>
<script>
import IconClose from "@/components/icons/IconCloseBold.vue";
import Tag from "@/components/Tag.vue";
import SubscriptionInput from "@/components/inputs/SubscriptionInput.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconFilter from "@/components/icons/IconFilterPublic.vue";

export default {
  components: { Tag, IconClose, SubscriptionInput, IconButton, IconFilter },
  props: {
    items: { type: Array },
    parentClientRect: { type: [DOMRect, Object] },
    isPublic: { type: Boolean },
    isSearch: { type: Boolean },
    isFilter: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: false,
      visible: false,
      isDown: false,
      search: "",
      searchItems: [],
    };
  },
  created() {
    this.searchItems = this.items;
  },
  methods: {
    setRect() {
      let parentClientRect = this.parentClientRect;
      let el = this.$refs.additionalPopUp;
      let elClientRect = el.getBoundingClientRect();

      let rightDiff = parentClientRect.right - elClientRect.right;
      let topDiff = elClientRect.top - elClientRect.height - 65 - parentClientRect.top;

      if (rightDiff > 0) {
        el.style.right = 0;
      } else {
        el.style.right = `${elClientRect.right - parentClientRect.right + 15}px`;
      }

      if (topDiff < 0) {
        el.style.top = "20px";
      } else {
        el.style.top = "-40px";
      }
      this.visible = this.items ? true : false;
    },
    hidePopUp() {
      this.visible = false;
      this.selected = false;
      this.search = "";
      this.$refs.additionalPopUp.style.top = "0";
      this.$refs.additionalPopUp.style.right = "0";
    },
    mousedown(e) {
      let tableScrolled = this.$refs.additionalPopUp;
      this.startX = e.pageX - tableScrolled.offsetLeft;
      this.scrollLeft = tableScrolled.scrollLeft;
    },
    mousemove(e) {
      let tableScrolled = this.$refs.additionalPopUp;
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - tableScrolled.offsetLeft;
      const walk = (x - this.startX) * 3; //scroll-fast
      tableScrolled.scrollLeft = this.scrollLeft - walk;
    },
    mouseenter(e) {
      this.$emit("getParentClientRect");
      clearTimeout(this.timer);
    },
    mouseleave(e) {
      this.timer = setTimeout(() => {
        this.isDown = false;
        this.hidePopUp();
      }, 600);
    },
    inputSearch(value) {
      this.search = value;
      if (this.isSearch) {
        let search = this.search.toUpperCase();
        const searchItems = this.items.filter(item => {
          let title = item.title.toUpperCase();
          return title.includes(search);
        });
        this.searchItems = searchItems;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.additional-tags {
  position: relative;
  cursor: pointer;
  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid $black;
    color: $black;
    background-color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.15;
    cursor: pointer;
  }
  &:hover {
    .additional-tags__count {
      background-color: $lightBlack;
      color: $white;
    }
    .additional-tags__count--selected {
      background-color: $white;
      color: $black;
    }
  }
  &__body {
    max-width: 50rem;
    overflow-x: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    display: flex;
    border-radius: 12px;
    padding: 10px 12px;
    background-color: $white;
    box-shadow: 0 2px 16px rgba($color: #000000, $alpha: 0.25);
    z-index: 2;
    &--visible {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
    @media screen and (max-width: $sm) {
      max-width: 90vw;
      right: 0 !important;
      top: 20px !important;
      transform: translate(0, 35px) !important;
    }
    .tag {
      margin: 0 10px 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &__item {
    &::v-deep .tag__text {
      font-size: 12px;
    }
  }
  &__container {
  }
  &__search {
    margin-bottom: 10px;
    &-clear {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }
  &--public {
    .additional-tags {
      &__count {
        font-size: 14px;
        line-height: 1.285;
        letter-spacing: 0;
        height: 4.8rem;
        background: transparent;
        border: 0.4px solid #a8a8a8;
        border-radius: 20px;
        padding: 0 20px;
        transition: all 0.3s ease;
        margin: 5px 0 5px 10px;
        color: $white;
        min-width: 60px;

        &--selected {
          background-color: $white;
          color: $black;
        }
      }

      &__body {
        min-width: 8rem;
        min-height: 4.8rem;
        max-height: 59rem;
        flex-direction: column;
        background-color: rgb(17, 17, 17);
        border: 1px solid $white;
        border-radius: 14px;
        .tag {
          margin: 0 0 10px 0;
        }
      }
    }
  }
}
</style>

