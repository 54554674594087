<template>
  <div class="form-control" v-click-outside="hideSelect">
    <div
      class="form-control__select"
      :class="{
        'form-control__select--disabled': disabled || visible,
      }"
      @click="visible = !visible"
    >
      {{ defaultLabel }} {{ element }}
    </div>
    <div
      class="form-control__items"
      :class="{ 'form-control__items--visible': !disabled ? visible : false }"
    >
      <div class="form-control__items_body">
        <ul class="list">
          <li v-for="i in items" :key="i.id">
            <a
              class="list__item"
              :class="{
                'list__item--active': element === i.title,
              }"
              @click.prevent="
                $emit('input', {
                  id: i.id,
                  title: i.title,
                });
                visible = false;
              "
              >{{ i.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
export default {
  props: {
    defaultLabel: { type: String, default: "Sort by: " },
    items: { type: Array },
    selected: { type: [String, Number], required: false },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: false,
      element: "",
    };
  },
  created() {
    this.takeSelect();
  },
  watch: {
    selected() {
      this.takeSelect();
    },
  },
  methods: {
    takeSelect() {
      for (let el of this.items) {
        if (this.selected == el.id) {
          this.element = el.title;
        }
      }
    },
    hideSelect() {
      this.visible = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  min-width: 215px;
  margin: 5px 0;
  cursor: pointer;
  &__select {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.8rem;
    padding: 0 20px;
    font-family: $fontFrutiger;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $white;
    background: $lightBlack;
    border: 0.4px solid #a8a8a8;
    border-radius: 20px;
    transition: all 0.3s ease;

    &--disabled {
      opacity: 0.6;
      cursor: default;
    }
    &_label {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);
      @include type($fw: 500);
      padding: 1px 1.1rem;
      background-color: $white;
      transition: all 0.1s ease;
      pointer-events: none;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
      &--selected {
        top: 0;
      }
      &_required {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        color: $error;
        margin-left: 4px;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }
  &--invalid {
    .form-control__select {
      border: 1px solid $error;
      color: $error;
    }
  }
  &__items {
    min-width: 215px;
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 8;
    padding: 9px 0;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    &--visible {
      visibility: visible;
      opacity: 1;
      max-height: 500px;
    }
    &_body {
      border-radius: 12px;
      border: 1px solid #a2a2a2;
      background: $lightBlack;
      padding: 10px 0;
      .list {
        overflow: hidden;
        overflow-y: auto;
        max-height: 280px;
        &__item {
          display: block;
          padding: 3px 15px;
          font-size: 14px;
          line-height: 1.8;
          background-color: transparent;
          color: $white;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          &:hover,
          &--active {
            background-color: $white;
            color: $lightBlack;
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          a {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }
}
</style>